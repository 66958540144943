import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'Afaceri de aur';

  cookies = false;

  ngOnInit(): void {
    if (!localStorage.getItem('cookie')) {
      this.cookies = true;
    }
  }

  saveCookies() {
    localStorage.setItem('cookie', 'cookie');
    this.cookies = false;
  }
}
