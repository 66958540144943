
<router-outlet></router-outlet>
<div *ngIf="cookies" class="cookies">
   <div>
       <p>
           Prin utilizarea site-ului nostru, confirmați că ați citit și ne-ați înțeles <a routerLink="/politica-cookies">Politica cookies</a>
       </p>
       <button (click)="saveCookies()" class="btn btn-got">Am ințeles</button>
   </div>
</div>

