import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from './_helpers/token.interceptor';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {NgxMaskModule} from "ngx-mask";
import {TooltipModule} from "ng2-tooltip-directive";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {ExtraOptions, RouterModule} from "@angular/router";
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import {environment} from "../environments/environment";
import {PhoneNumberDirective} from "./_directives/phoneNumber.directive";
import {LightgalleryModule} from "lightgallery/angular";
import {LazyLoadImageModule} from "ng-lazyload-image";
import { ServiceWorkerModule } from '@angular/service-worker';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled', // Scroll to top on route change
};

registerLocaleData(localeRo, 'ro');

@NgModule({
  declarations: [
    AppComponent,
    PhoneNumberDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true,
      timeOut: 5000,
      preventDuplicates: true
    }),
    NgxMaskModule.forRoot(),
    TooltipModule,
    AngularEditorModule,
    RouterModule.forRoot([], routerOptions),
    NgbDatepickerModule,
    RecaptchaV3Module,
    LightgalleryModule,
    LazyLoadImageModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    }
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }
