import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../_services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    public authService: AuthService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let error = '';

      console.log('INTERCEPT ERROR: ', err.status);

      if (err.status === 401) {
        this.authService.logout();
        error = 'O eroare a aparut in sistem. Incearca mai tarziu.';
      } else {
        error = 'O eroare a aparut in sistem. Incearca mai tarziu.';
      }
      return throwError(error);
    }));
  }

}
