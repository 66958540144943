import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {isPlatformBrowser} from '@angular/common';

const headers = new HttpHeaders({
  'Content-Type':  'application/json'
});

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private isAuthenticated: boolean | undefined;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  getToken(): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('token')) {
        return JSON.parse(JSON.stringify(localStorage.getItem('token')));
      }
    }
  }

  public authenticated(): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('token') && localStorage.getItem('currentUser')) {
        return this.isAuthenticated = true;
      }
    }
  }

  public hasProfile(): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('currentUser')) {
        return JSON.parse(JSON.stringify(localStorage.getItem('currentUser'))).profile;
      } else {
        return true;
      }
    }
  }

  getRole(): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('currentUser')) {
        return JSON.parse(JSON.stringify(localStorage.getItem('currentUser'))).role;
      }
    }
  }

  getUser(): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('currentUser')) {
        return JSON.parse(JSON.stringify(localStorage.getItem('currentUser')));
      }
    }
  }

  logout() {
    localStorage.removeItem('currentUser');
    location.reload();
    return;
  }

  adminAuthenticated(): any  {
    if (localStorage.getItem('token') && localStorage.getItem('currentUser')) {
      if (JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('currentUser')))).role == 'admin') {
        return this.isAuthenticated = true;
      }
    }
  }

  login(data: any) {
    return this.http.post( environment.apiUrl + 'api/login', data, {headers})
      .pipe(map((res: any) => {
        if (res.response && res.response.token) {
          let user: any = res.response.user;
          if (res.response.resume) {
            user.resume = res.response.resume.id;
          }

          if (res.response.applied && res.response.applied.length > 0) {
            user.applied = res.response.applied;
          } else {
            user.applied = [];
          }

          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('token', JSON.stringify(res.response.token));
        }
        return res;
      }));
  }

  recoverPassword(data: any) {
    return this.http.post(environment.apiUrl + 'api/forgot-password', data, {headers})
        .pipe(map((res: any) => {
          return res;
        }));
  }

  contact(data: any) {
    return this.http.post( environment.apiUrl + 'api/contact', data,{headers})
        .pipe(map((res: any) => {
          return res;
        }));
  }

  register(data: any) {
    if (data.terms) {
      data.terms = 1;
    }
    return this.http.post( environment.apiUrl + 'api/register', data, {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  changePassword(data: any) {
    return this.http.post( environment.apiUrl + 'api/reset-password', data, {headers})
        .pipe(map((res: any) => {
          return res;
        }));
  }

  updateAccount(data: any) {
    return this.http.post( environment.apiUrl + 'api/users/saveObject', data, {headers})
        .pipe(map((res: any) => {
          return res;
        }));
  }

  activateAccount(data: { id: any; hash: any; expires: any; signature: any; }) {
    const params = new HttpParams().set('expires', data.expires).set('signature', data.signature);
    return this.http.get(environment.apiUrl + 'api/verify/' + data.id + '/' + data.hash, {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

}


